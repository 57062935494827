// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import buildingGallery from '../assets/buildinggallery.jpg'; 
// import gandl from '../assets/gandl.jpg'; 
// import lawncare1 from '../assets/lawncare1.jpg'; 
// import residential from '../assets/residentialcleaning.jpg'; 
// import officeClean from '../assets/officecleaninggallery.jpg'; 

// const Gallery = () => {
//   const images = [
//     buildingGallery,
//     gandl,
//     lawncare1,
//     residential,
//     officeClean,
    
//   ];

//   return (
//     <Container className="my-5">
//       <h2 className="text-center mb-4">Gallery</h2>
//       <Row xs={1} md={2} lg={3} className="g-4">
//         {images.map((image, index) => (
//           <Col key={index}>
//             <img src={image} className="img-fluid" alt={`Gallery ${index}`} />
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   );
// };

// export default Gallery;


import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import officecleaning3 from '../assets/officecleaning3.jpg'; 
import renovation3 from '../assets/renovationwork3.jpg'; 
import gandl from '../assets/gandl.jpg'; 
import lawncare1 from '../assets/lawncare1.jpg'; 
import residential from '../assets/residentialcleaning.jpg'; 
import officeClean from '../assets/officecleaninggallery.jpg'; 
import windowClean from '../assets/windowcleaning.jpg';

const Gallery = () => {
  const images = [
    renovation3,
    gandl,
    lawncare1,
    officecleaning3,
    officeClean,
    windowClean,
  ];

  // Define a consistent style object for all images
  const imageStyle = {
    width: '100%', // 100% of the container for responsiveness
    height: '300px', // Fixed height for consistency
    objectFit: 'cover', // This will cover the area with the image without stretching it
    borderRadius: '0.25rem' // Optional: if you want rounded corners
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Gallery</h2>
      <Row xs={1} md={2} lg={3} className="g-4">
        {images.map((image, index) => (
          <Col key={index}>
            <img src={image} style={imageStyle} className="img-fluid" alt={`Gallery item ${index}`} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Gallery;
