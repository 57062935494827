// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';

// const Footer = () => {
//   return (
//     <footer className="mt-5 py-3 bg-dark text-white">
//       <Container>
//         <Row>
//           <Col md={6} className="mb-3 mb-md-0">
//             <h5>MILTISOLUTION SERVICE</h5>
//             <p>Providing exceptional service since [Year].</p>
//           </Col>
//           <Col md={6} className="text-md-end">
//             <p>Contact us at: info@example.com</p>
//             <p>Follow us on social media</p>
//             {/* Social media icons or links */}
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;


import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="mt-5 py-3 bg-light text-dark">
      <Container>
        <Row>
          <Col md={4} className="mb-3 mb-md-0">
            <h5>MULTISOLUTION SERVICES</h5>
            <p>Providing exceptional service since 2023.</p>
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
            <p><strong>William Gadri</strong></p>
            <p><strong>Phone:</strong> +4917668166299</p>
            <p><strong>Email:</strong> info@multisoloutionservices.de</p>
          </Col>
          <Col md={4} className="text-md-end">
            <p><strong>Address:</strong> Herzogstr 10, 68723 Schwetzingen, Deutschland.</p>
            <p>Follow us on social media</p>
            {/* Social media icons or links */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
