import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import Header from './components/Header';
import Services from './components/Services';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import ManageContacts from './components/ManageContacts';



function App() {
  return (
    <div>
      <Header />
      <section id="home">
        {/* Content for Home, could be a Hero component or just a welcome text */}
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="gallery">
        <Gallery />
      </section>
      <section id="testimonials">
        <Testimonials />
      </section>
      <section id="contact">
        <ContactForm />
      </section>
      <section id="manage-contacts">
        <ManageContacts />
      </section>
      <Footer />
    </div>
  );
}

export default App;