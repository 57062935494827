import React from 'react';
import { Navbar, Nav, Container, Image, Button } from 'react-bootstrap';
import multiSolutionLogo from '../assets/multisolution.png'; // Ensure this is the path to your high-resolution logo

const Header = () => {
    return (
        <Navbar bg="light" variant="light" expand="lg" className="py-3" style={{ fontSize: '1.3rem' }}>
            <Container>
                <Navbar.Brand href="#home" className="me-auto">
                    <Image alt="MILTISOLUTION SERVICE" src={multiSolutionLogo} height="150" className="d-inline-block align-top" style={{ maxHeight: '100px' }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                    <Nav className="align-items-center">
                        {/* Navigation links will inherit font size from Navbar */}
                    </Nav>
                </Navbar.Collapse>
                <Nav className="ms-auto">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#services">Services</Nav.Link>
                    <Nav.Link href="#gallery">Gallery</Nav.Link>
                    <Nav.Link href="#testimonials">Testimonials</Nav.Link>
                    <Nav.Link href="#contact">Contact</Nav.Link>
                    <Nav.Link href="#login">Log In</Nav.Link>
                    <Button variant="outline-success" href="#call">Call Today</Button>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default Header;
