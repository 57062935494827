import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { projectFirestore } from '../firebase/config'; 
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';


const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Here you would handle the form submission, e.g., send data to a server
  //   console.log(formData);
  //   alert('Message sent!');
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Reference to the 'contactus' collection
      const collectionRef = collection(projectFirestore, 'contactus');
      
      // Add a new document with form data and timestamp
      const docRef = await addDoc(collectionRef, {
        ...formData,
        createdAt: serverTimestamp() // Adds a timestamp field
      });
      
      console.log("Document written with ID: ", docRef.id);
      alert('Message sent!');
      
      // Reset form data
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
      
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('Error sending message. Please try again.');
    }
  };
  

  return (
    <Container>
      <h2 className="text-center my-4">Contact Us</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" name="name" value={formData.name} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" name="email" value={formData.email} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control type="text" placeholder="Subject" name="subject" value={formData.subject} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Your message" name="message" value={formData.message} onChange={handleChange} />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default ContactForm;
