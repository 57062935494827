import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDAz9-QB9av9NiPZSBwDkaYAIGYvT2sh9A",
    authDomain: "multisolutions-ec84b.firebaseapp.com",
    projectId: "multisolutions-ec84b",
    storageBucket: "multisolutions-ec84b.appspot.com",
    messagingSenderId: "582897587161",
    appId: "1:582897587161:web:d832c7360168bea537bfc8",
    measurementId: "G-C0YHB8ZLNC"
  };

const app = initializeApp(firebaseConfig);

const projectAuth = getAuth(app);
const projectFirestore = getFirestore(app);
const projectStorage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
const projectFunctions = getFunctions(app);

export { projectAuth, projectFirestore, projectStorage, googleProvider, projectFunctions, serverTimestamp };






