import React from 'react';
import { Carousel, Card } from 'react-bootstrap';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      text: "This service transformed our garden into a paradise! We're thrilled with the results and the professionalism of the team.",
      author: "Jane Doe",
    },
    {
      id: 2,
      text: "Highly recommend! Their attention to detail and passion for landscaping is evident in every project they undertake.",
      author: "John Smith",
    },
    // Add more testimonials as needed
  ];

  return (
    <Carousel className="d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
      {testimonials.map((testimonial) => (
        <Carousel.Item key={testimonial.id}>
          <Card className="bg-dark text-white text-center mx-auto" style={{ maxWidth: '1300px', minHeight: '300px' }}>
            <Card.Body className="d-flex flex-column justify-content-center align-items-center" style={{ height: '300px' }}>
              <Card.Text>{testimonial.text}</Card.Text>
              <Card.Title>{testimonial.author}</Card.Title>
            </Card.Body>
          </Card>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Testimonials;
