import React, { useState, useEffect } from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import { projectFirestore } from '../firebase/config';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';

const ManageContacts = () => {
    const [contacts, setContacts] = useState([]);

    // Fetch contacts from Firestore
    useEffect(() => {
        const fetchContacts = async () => {
            const querySnapshot = await getDocs(collection(projectFirestore, "contactus"));
            setContacts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };

        fetchContacts();
    }, []);

    // Delete a contact
    const handleDelete = async (id) => {
        await deleteDoc(doc(projectFirestore, "contactus", id));
        setContacts(contacts.filter(contact => contact.id !== id));
    };

    return (
        <Container>
            <h2 className="my-4 text-center">Manage Contact Requests</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Subject</th>
                        <th>Message</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.map(({ id, name, email, subject, message }) => (
                        <tr key={id}>
                            <td>{name}</td>
                            <td>{email}</td>
                            <td>{subject}</td>
                            <td>{message}</td>
                            <td>
                                <Button variant="danger" onClick={() => handleDelete(id)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default ManageContacts;
