import React from 'react';
import { Card, Button, Row, Col, Container } from 'react-bootstrap';
import lawnCare from '../assets/lawnmowing.jpg';
import officeCleaning from '../assets/officecleaning.jpg';
import renovations from '../assets/renovationwork.jpg';
import apartment from '../assets/apartmentadmin.jpg';
import courier from '../assets/courierservices.jpg';
import glass from '../assets/glasscleaning.jpg';

const Services = () => {
  const services = [
    {
      id: 1,
      title: 'Lawn Care',
      description: 'Comprehensive lawn care services including mowing, edging, and fertilization.',
      imageUrl: lawnCare,
    },
    {
      id: 2,
      title: 'Garden Maintenance',
      description: 'Expert garden maintenance to keep your flowers, shrubs, and trees healthy and beautiful.',
      imageUrl: officeCleaning,
    },
    {
      id: 3,
      title: 'Renovation',
      description: 'Comprehensive lawn care services including mowing, edging, and fertilization.',
      imageUrl: renovations,
    },
    {
      id: 4,
      title: 'Apartment Administration',
      description: 'Comprehensive lawn care services including mowing, edging, and fertilization.',
      imageUrl: apartment,
    },
    {
      id: 5,
      title: 'Courier Services',
      description: 'Expert garden maintenance to keep your flowers, shrubs, and trees healthy and beautiful.',
      imageUrl: courier,
    },
    {
      id: 6,
      title: 'Glass Cleaning',
      description: 'Comprehensive lawn care services including mowing, edging, and fertilization.',
      imageUrl: glass,
    },

    // Add more services as needed
  ];

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Our Services</h2>
      <Row xs={1} md={2} lg={3} className="g-4">
        {services.map((service) => (
          <Col key={service.id}>
            <Card>
              {/* <Card.Img variant="top" src={service.imageUrl} /> */}
              <Card.Img variant="top" src={service.imageUrl} style={{ height: '200px', objectFit: 'cover' }} />

              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
                <Button variant="primary">Learn More</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
